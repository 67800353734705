import React, { useState } from 'react';
import { StaticImage } from "gatsby-plugin-image"
import { ReactComponent as Arrow } from '../images/arrow.svg'
import { ReactComponent as BannerPic } from '../images/banner-pic.svg'
import { ReactComponent as Stepup01 } from '../images/stepup-01.svg'
import { ReactComponent as Stepup02 } from '../images/stepup-02.svg'
import { ReactComponent as Stepup03 } from '../images/stepup-03.svg'
import { ReactComponent as Stepup04 } from '../images/stepup-04.svg'
import { ReactComponent as Stepup05 } from '../images/stepup-05.svg'
import { ReactComponent as Stepup06 } from '../images/stepup-06.svg'

import tw from 'twin.macro'
import { Config } from '../utils/config'
import Title from '../components/styles/Title'

import Layout from "../components/layout"
import { GatsbySeo } from 'gatsby-plugin-next-seo';

// import Swiper JS
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Thumbs,EffectFade,Autoplay } from 'swiper/core';
// import Swiper styles
import 'swiper/swiper-bundle.css'

// install Swiper's Thumbs component
SwiperCore.use([Thumbs,EffectFade,Autoplay]);

export default function IndexPage({location}) {

   const [thumbsSwiper, setThumbsSwiper] = useState(null);

   const styles = {
    featuresCe: ()=>[
        tw`flex items-start justify-between rounded-md bg-white py-10 px-[30px]`,
        tw`transform transition-all hover:-translate-y-1`,
        tw`sm:flex-col sm:px-4 sm:py-5`
    ],
    featuresIco: ()=>[
        tw`bg-blue text-blue bg-opacity-10 rounded-full w-[68px] h-[68px] flex items-center justify-center`
    ],
    featuresTxt: ()=>[
        tw`w-[calc(100% - 88px)] sm:w-full sm:mt-6`
    ],
    canSlide: ()=>[
        tw`!h-auto bg-yellow bg-opacity-10 text-yellow-darkest rounded-md text-lg p-4 cursor-pointer`
    ],
    canText: ()=>[
        tw`hidden pt-4 text-gray`
    ]
   }

    return (
        <Layout>
            <GatsbySeo canonical={location.href} title="Best Free Text to Speech Online Tool with Natural Sounding (TTS)" description="NaturalVoicer is the best free text to speech online software, which helps you convert text to voice anywhere at anytime." />
            <div className="w-full container mx-auto pt-[120px] pb-[100px]">
                <div className="flex items-center lg:flex-col">
                    <div className="w-[620px] lg:w-full lg:mb-8 lg:text-center">
                        <h1 css={Title("h1")}>Free Text to Speech Online with Natural Voices</h1>
                        <p className="text-lg text-gray pt-6 pb-9">Convert Texts, PDFs & Ebooks to Speech Voices As You Like</p>
                        <ul className="list-ok mb-10 lg:inline-flex">
                            <li>Up to 40+ language & 100+ voices</li>
                            <li>Online voice generator</li>
                            <li>High quality natural sounding</li>
                            <li>Fast, easy & free</li>
                        </ul>
                        <a href={Config.appDomain} className="w-[208px] h-[56px] lg:mx-auto bg-blue flex items-center text-white text-lg justify-center rounded-md hover:text-white hover:bg-blue-darkest">Start TTS Free
                            <Arrow className="w-3 ml-[14px]"/>
                        </a>
                    </div>
                    <BannerPic className="max-w-[577px] w-full"/>
                </div>
            </div>
            <div className="feature relative bg-light py-28 overflow-hidden">
                <div className="w-full container mx-auto relative z-[1]">
                    <h2 css={Title("h2")}>From Text to Speech, NaturalVoicer is the Best</h2>
                    <p className="text-lg text-gray text-center pt-7 pb-[60px]">If you need to generate voices for YouTube, Vimeo, PPT or a website, or simply have difficulty reading large amounts of text, you should definitely consider Naturalvoicer. Fast, easy, and natural-sounding, it's the perfect online voice generator. </p>
                    <div className="grid grid-cols-2 gap-[30px] lg:grid-cols-1">
                        <div css={styles.featuresCe}>
                            <div css={styles.featuresIco}>
                                <svg width="42" viewBox="0 0 1024 1024" fill="currentColor">
                                <path d="M1024 512C1024 230.4 793.6 0 512 0S0 230.4 0 512c0 262.4 198.4 473.6 448 505.6 12.8 0 32 6.4 51.2 6.4h19.2C800 1017.6 1024 793.6 1024 512zm-70.4 6.4c0-25.6-6.4-51.2-12.8-76.8V416c6.4 32 12.8 57.6 12.8 89.6v12.8zM70.4 512c0-32 6.4-64 6.4-89.6 19.2 19.2 64 19.2 76.8-12.8 19.2 12.8 44.8 12.8 44.8 38.4 0 76.8 0 160 70.4 160 0 0 38.4 12.8 57.6 57.6 6.4 12.8 32 0 57.6 0 12.8 0 0 19.2 0 70.4 0 44.8 102.4 121.6 102.4 121.6 0 32 0 57.6 6.4 76.8-25.6 0-44.8 0-57.6 6.4A434.2 434.2 0 0170.4 512zm550.4 422.4c0-12.8-12.8-19.2-32-12.8 12.8-64 19.2-96 51.2-121.6 44.8-38.4 6.4-83.2-25.6-76.8-25.6 6.4-12.8-32-32-32-25.6 0-51.2-51.2-89.6-64-19.2-6.4-38.4-32-64-32s-57.6 19.2-57.6 6.4c0-51.2-6.4-89.6-6.4-108.8 0-12.8-6.4-6.4 25.6-6.4 19.2 0 6.4-38.4 25.6-38.4 19.2 0 57.6 19.2 70.4 6.4 12.8-6.4 70.4 166.4 70.4 25.6 0-19.2-6.4-44.8 0-57.6 32-57.6 64-108.8 64-115.2 0-6.4-32-6.4-57.6 0-6.4 0 0 19.2-12.8 19.2-44.8 12.8-89.6-12.8-76.8-32 12.8-25.6 70.4-12.8 70.4-57.6 0-25.6 6.4-57.6 6.4-76.8 64 12.8 57.6-83.2-38.4-89.6 192 0 352 121.6 409.6 294.4 0 0-6.4-6.4-12.8-6.4-25.6-70.4-96-19.2-76.8 44.8-128 96-96 166.4-51.2 204.8a557.1 557.1 0 0157.6 70.4c-12.8 44.8 51.2 25.6 89.6-44.8C889.6 780.8 768 896 620.8 934.4z"/>
                                </svg>
                            </div>
                            <div css={styles.featuresTxt}>
                                <h3 className="text-xl pb-5 font-bold">Supports Multi-language</h3>
                                <p>While most TTSreaders only support English, we have 50+ languages supported, like French, Portuguese, German, Japanese, Chinese, etc.</p>
                            </div>
                        </div>
                        <div css={styles.featuresCe}>
                            <div css={styles.featuresIco}>
                                <svg width="42" viewBox="0 0 1024 1024" fill="currentColor">
                                <path d="M649.68 393.64c-33.11 0-64.36-13.68-85.77-37.59a104.53 104.53 0 01-26.55-80.11l-.59-204.18 77.45-.22.6 208.38-.25 2.23a27.72 27.72 0 007.03 22.18c6.73 7.52 16.96 11.82 28.03 11.82h.12l217.57-.58.2 77.44-217.55.6z"/>
                                <path d="M795.1 992H228.84c-62.64 0-113.59-50.43-113.59-112.42V144.43c0-62 50.95-112.43 113.59-112.43h370.37l309.52 304.75v542.83c-.06 62-51.01 112.42-113.65 112.42zM228.84 107.4c-21.06 0-38.18 16.62-38.18 37.03v735.15c0 20.42 17.12 37.01 38.18 37.01h566.24c21.05 0 38.18-16.6 38.18-37V368.32L568.32 107.41z"/>
                                <path d="M756.91 670.55H583.76a39.36 39.36 0 01-39.32-39.33v-1.81a39.36 39.36 0 0139.32-39.3h173.15a39.35 39.35 0 0139.33 39.32v1.82a39.36 39.36 0 01-39.33 39.3zm-6.87 158.8H354.91a35.9 35.9 0 01-35.77-35.73v-6.28a35.9 35.9 0 0135.77-35.76h395.13a35.9 35.9 0 0135.77 35.76v6.28a35.9 35.9 0 01-35.77 35.76z"/>
                                </svg>
                            </div>
                            
                            <div css={styles.featuresTxt}>
                                <h3 className="text-xl pb-5 font-bold">Support Multiple File Formats</h3>
                                <p>You can upload pdf, txt, doc(x), epub and convert all these files into audio file. You can listen on the go or download MP3.</p>
                            </div>
                        </div>
                        <div css={styles.featuresCe}>
                            <div css={styles.featuresIco}>
                                <svg width="42" viewBox="0 0 200 200" fill="currentColor">
                                <path d="M180.95 176.19h-14.29a14.3 14.3 0 01-14.29-14.29V90.48a52.37 52.37 0 00-52.39-52.37 52.37 52.37 0 00-52.37 52.37v71.43a14.3 14.3 0 01-14.29 14.29H19.05a14.3 14.3 0 01-14.29-14.29v-47.62A14.3 14.3 0 0119.05 100h14.29v-9.52a66.67 66.67 0 01133.34 0V100h14.29a14.3 14.3 0 0114.29 14.29v47.62a14.32 14.32 0 01-14.31 14.28zM33.33 119.05a4.76 4.76 0 00-4.76-4.76h-4.76a4.76 4.76 0 00-4.76 4.76v38.1a4.76 4.76 0 004.76 4.76h4.76a4.76 4.76 0 004.76-4.76v-38.1zm147.62 0a4.76 4.76 0 00-4.76-4.76h-4.76a4.76 4.76 0 00-4.76 4.76v38.1a4.76 4.76 0 004.76 4.76h4.76a4.76 4.76 0 004.76-4.76v-38.1z"/>
                                <path d="M135.71 145.24h-13.02L118 159.71a7.6 7.6 0 11-15 .17l-8.79-24.1-1.29 3.95a6.58 6.58 0 01-1.24 2.26 7.12 7.12 0 01-5.98 3.26H61.9a7.14 7.14 0 010-14.28h18.86l5.95-18.29.14-.34a7.13 7.13 0 0113.69-.97l9.57 26.33.4-1.21a19.14 19.14 0 0110.9-5.52h14.29a7.14 7.14 0 01.01 14.27z"/>
                                </svg>
                            </div>
                            <div css={styles.featuresTxt}>
                                <h3 className="text-xl pb-5 font-bold">Natural Sounding Voices</h3>
                                <p>We provide the most human-like, high-fidelity, accurate voices, including male and female voices in different accents or tones.</p>
                            </div>
                        </div>
                        <div css={styles.featuresCe}>
                            <div css={styles.featuresIco}>
                                <svg width="42" viewBox="0 0 1024 1024" fill="currentColor"><path d="M860.2 163.8C768 66.6 650.2 20.5 512 20.5s-250.9 46-348.2 143.3C66.6 261.1 20.5 378.8 20.5 512s46 250.9 143.3 348.2c97.3 97.2 210 143.3 348.2 143.3 133.1 0 250.9-51.2 353.3-148.5 92.1-92.1 138.2-209.9 138.2-343s-46-256-143.3-348.2zm-61.5 629.8c-81.9 82-179.2 122.9-286.7 122.9s-204.8-41-286.7-117.8C148.5 716.8 107.5 619.5 107.5 512c0-46 5.1-92.2 20.5-133.1l250.9 112.6a516.7 516.7 0 0176.8 35.9L512 553l46 20.4c10.3 5.2 15.4 15.4 15.4 30.8 0 35.8-30.7 51.2-61.4 51.2-46 0-76.8-15.4-107.5-46.1l-66.6 66.5a223 223 0 00148.5 56.4V809h56.3v-76.8c66.6-5.2 122.9-46.1 133.1-107.6l184.4 82c-10.3 30.7-35.9 61.4-61.5 87zM650.2 506.9c-25.6-10.3-51.2-20.5-76.8-35.9l-41-20.4L466 425c0-5.2-5.1-10.3-5.1-15.4 0-30.7 35.8-35.8 61.4-35.8 30.8 0 61.5 10.2 82 30.7l66.5-66.6c-41-30.7-87-41-122.9-46V215h-56.3v76.8c-61.4 0-112.6 30.8-128 87l-189.4-87c15.3-25.6 35.8-46 56.3-66.5 76.8-76.8 169-117.8 281.6-117.8 112.6 0 204.8 41 286.7 117.8 76.8 76.8 117.8 174 117.8 286.7 0 35.8-5.1 71.7-10.3 107.5L650.2 507z"/></svg>
                            </div>
                            <div css={styles.featuresTxt}>
                                <h3 className="text-xl pb-5 font-bold">Free for Commercial Use</h3>
                                <p>You can use our voices free for commercial usage, like making voice overs for Youtube videos, or creating eLearning course materials.</p>
                            </div>
                        </div>
                        <div css={styles.featuresCe}>
                            <div css={styles.featuresIco}>
                                <svg width="42" viewBox="0 0 1024 1024" fill="currentColor">
                                <path d="M68.74 764.54a41.28 41.28 0 0071.48-41.28 426.82 426.82 0 11166.4 161.92 41.28 41.28 0 10-39.23 72.7A509.44 509.44 0 1068.67 764.62z"/>
                                <path d="M371.33 353.15l-66.37 66.37a41.28 41.28 0 01-58.37-58.37l134.27-134.27a41.22 41.22 0 0173.1 26.3v513.03a41.28 41.28 0 01-82.57 0V353.15zm276.73 313.09l66.37-66.37a41.28 41.28 0 0158.37 58.37l-134.2 134.4a41.22 41.22 0 01-73.16-26.43V253.18a41.28 41.28 0 0182.62 0v413.06z"/>
                                </svg>
                            </div>
                            <div css={styles.featuresTxt}>
                                <h3 className="text-xl pb-5 font-bold">Playback, Speedup & Pause </h3>
                                <p>You can choose between diffrerent reading speeds, pause or stop speaking, continue from where you stopped last time.</p>
                            </div>
                        </div>
                        <div css={styles.featuresCe}>
                            <div css={styles.featuresIco}>
                            <svg width="42" viewBox="0 0 1024 1024" fill="currentColor"><path d="M945.2 174.6a36.2 36.2 0 00-35.4-35.8l-39.4-.6c-2.6 0-84-2.5-167-36.3C616 66 559.2 25.6 557.2 24.6L533.5 7.2a35.3 35.3 0 00-42 0L468 24c-.5.5-58.4 42-146.5 77.8a531.2 531.2 0 01-166.9 36.3h-40.4a36.2 36.2 0 00-35.4 35.9v400.4c0 100.3 57.4 212.4 157.7 308.2 91.2 87 196.7 141.3 275.5 141.3 78.8 0 184.3-54.3 275.5-141.3 100.3-95.8 157.7-207.9 157.7-308.2v-400zM737.8 831C662 903.2 570.8 952.3 512 952.3S362 903.7 286.2 831C200.2 749 151 655.4 151 575V210h4.1a603 603 0 00193-41.6A839.3 839.3 0 00509.5 82l2.6-2 2.6 2c7.6 5.7 68.6 48.1 161.8 86a603 603 0 00193 41.5h4v365c0 81-49.6 174.1-135.6 256.6z"/><path d="M669.2 352.3L464.4 557l-86.5-86c-18-18-46.6-18-64.6 0s-17.9 46.5 0 64.5l118.3 118.2c8.7 8.7 20 13.3 32.3 13.3s23.5-4.6 32.2-13.3l237-237c18-18 18-46.6 0-64.5-17.3-18-46-18-64 0z"/></svg>
                            </div>
                            <div css={styles.featuresTxt}>
                                <h3 className="text-xl pb-5 font-bold">100% Free and Safe</h3>
                                <p>We provide free TTS online services (Characters &lt; 3000/day). For keeping security, we will never save any audios. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white can-switch">
                <div className="w-full container mx-auto pt-20 pb-10 sm:py-10">
                    <h2 css={Title("h2")}>What Can NaturalVoicer Help with</h2>
                    <p className="text-lg text-gray pb-12 text-center pt-7">NaturalVoicer makes your life easier and more interesting.</p>
                    <div className="flex justify-between xl:flex-col">
                        <Swiper
                            slidesPerView={6}
                            allowTouchMove={false}
                            spaceBetween={10}
                            direction='vertical'
                            onSwiper={setThumbsSwiper}
                            className="w-[600px] !mx-0 xl:w-full xl:mb-10"
                        >
                            <SwiperSlide className="!h-auto">
                                <div css={styles.canSlide}>
                                    <h4 className="text-xl font-bold">People with Dyslexia</h4>
                                    <div className="txt" css={styles.canText}>
                                        <p className="pb-4">I have severe dyslexia. Reading has always been a challenge for me. Thanks to Naturalvoicer, I finally don’t have to endure the pain of staring at the phone screen all the time, which makes me more efficient than before.</p>
                                        <div className="flex items-center justify-between"> <span className="font-bold text-sm">— Felix Chapman</span> </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="!h-auto">
                                <div css={styles.canSlide}>
                                    <h4 className="text-xl font-bold">Students & Learners</h4>
                                    <div className="txt" css={styles.canText}>
                                        <p className="pb-4">I am a bilingual graduate student. I found NaturalVoicer to be a great tool to help me complete the course. I will upload my coursework and let NaturalVoicer read back what I wrote, to help me proofread the content a second time. To be honest, it is easier to find and correct mistakes than to read it word by word.</p>
                                        <div className="flex items-center justify-between"> <span className="font-bold text-sm">— Billie Murray</span> </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="!h-auto">
                                <div css={styles.canSlide}>
                                    <h4 className="text-xl font-bold">Video Makers</h4>
                                    <div className="txt" css={styles.canText}>
                                        <p className="pb-4">As a YouTuber whose native language is not English, I seldom make voices in my videos before. BTW, my videos are mainly about smartphone reviews. Not talking really sounds strange, so there are little followers. Now with NaturalVoicer, I can add authentic voiceovers, and my number of followers and interaction rate have also increased.</p>
                                        <div className="flex items-center justify-between"> <span className="font-bold text-sm">— Jewel Ishaan</span> </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="!h-auto">
                                <div css={styles.canSlide}>
                                    <h4 className="text-xl font-bold">Multitaskers</h4>
                                    <div className="txt" css={styles.canText}>
                                        <p className="pb-4">My commute time is very long, which takes three hours to drive. When I want to catch up on the news, podcasts and audiobooks,  I will convert some periodicals and e-books into MP3 with NaturalVoicer and then listen on the go. This stimulates my unlimited imagination and greatly reduces my fatigue during driving. </p>
                                        <div className="flex items-center justify-between"> <span className="font-bold text-sm">— Zane Miller</span> </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="!h-auto">
                                <div css={styles.canSlide}>
                                    <h4 className="text-xl font-bold">Visually Impaired Readers</h4>
                                    <div className="txt" css={styles.canText}>
                                        <p className="pb-4">I have severe floaters, so reading has always been a very difficult thing for me. My daughter guided me to use NaturalVoicer. It opened up a whole new world for me. I can choose what I need to know and it read out. This is a very valuable tool and I highly recommend it to anyone with vision problems.</p>
                                        <div className="flex items-center justify-between"> <span className="font-bold text-sm">— Kai Simpson</span> </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="!h-auto">
                                <div css={styles.canSlide}>
                                    <h4 className="text-xl font-bold">International Marketer</h4>
                                    <div className="txt" css={styles.canText}>
                                        <p className="pb-4">As an international salesman, it is not a rare thing to meet customers from other countries. Thanks to NaturalVoicer, it has more than 40 languages to help me handle daily cases and dialogues. So far I have successfully developed clients in France and Spain. </p>
                                        <div className="flex items-center justify-between"> <span className="font-bold text-sm">— Brynn Bradley</span> </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
    
                        <Swiper
                            thumbs={{ swiper: thumbsSwiper,slideThumbActiveClass:"canActiveThumb" }}
                            autoplay={{
                                delay: 5000,
                                stopOnLastSlide: false,
                                disableOnInteraction: true,
                            }}
                            className="max-w-[500px] !mx-0 w-full xl:!mx-auto"
                        >
                            <SwiperSlide className="flex justify-center items-center">
                                <Stepup01 className="w-full"/>
                            </SwiperSlide>
                            <SwiperSlide className="flex justify-center items-center">
                                <Stepup02 className="w-full"/>
                            </SwiperSlide>
                            <SwiperSlide className="flex justify-center items-center">
                                <Stepup03 className="w-full"/>
                            </SwiperSlide>
                            <SwiperSlide className="flex justify-center items-center">
                                <Stepup04 className="w-full"/>
                            </SwiperSlide>
                            <SwiperSlide className="flex justify-center items-center">
                                <Stepup05 className="w-full"/>
                            </SwiperSlide>
                            <SwiperSlide className="flex justify-center items-center">
                                <Stepup06 className="w-full"/>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
            <div className="work relative">
                <div className="w-full container mx-auto relative z-[1]">
                    <h2 css={Title("h2")} className="pb-10">Frequently Asked Questions</h2>
                    <details className="mb-5" open>
                        <summary>
                            <h3 className="text-lg text-gray font-semibold flex items-center justify-between bg-white py-5 px-6 shadow-faq rounded-sm">What is text to speech? <Arrow className="w-3" fill="currentColor"/></h3>
                        </summary>
                        <div className="bg-blue-darkest bg-opacity-5 py-4 px-6 text-base leading-7">
                            <p className="pb-4">A text-to-speech tool, also known as a text reader or text-to-speech software, is a technology for reading digital text aloud.</p>
                            <p>It was originally an assistive technology developed for the visually impaired to help them understand and read texts on a mobile phone or computer. Now it is also widely used in other fields, such as making eLearning courseware, YouTube voiceovers, etc.</p>
                        </div>
                    </details>
                    <details className="mb-5">
                        <summary>
                            <h3 className="text-lg text-gray font-semibold flex items-center justify-between bg-white py-5 px-6 shadow-faq rounded-sm">Which languages are supported by NaturalVoicer? <Arrow className="w-3" fill="currentColor"/></h3>
                        </summary>
                        <div className="bg-blue-darkest bg-opacity-5 py-4 px-6 text-base leading-7">
                            <p className="pb-4">We support US English, UK English, Spanish, Spanish Latin American, French, French Canadian, Arabic, Portuguese, Chinese, Chinese HongKong, Chinese TaiWan, Hindi, Brazillian, Aemenian, Australian English, Bangla Bangladesh, Bangla India, Czech, Danish, Deutsch, Dutch, Estonian, Fillipion, Finnish, Greek, Hungarian, Indonesian, Italian, Janpanese, Korean, Latin, Netpali, Norwegain, Polish, Romanian, Russian, Sinhala, Slovak, Swedish, Tamil and more. </p>
                            <p><a href={Config.appDomain} className="text-blue underline">Try it now &gt;&gt; </a></p>
                        </div>
                    </details>
                    <details className="mb-5">
                        <summary>
                            <h3 className="text-lg text-gray font-semibold flex items-center justify-between bg-white py-5 px-6 shadow-faq rounded-sm">Why there is no sound when I click play? <Arrow className="w-3" fill="currentColor"/></h3>
                        </summary>
                        <div className="bg-blue-darkest bg-opacity-5 py-4 px-6 text-base leading-7">
                            <ul className="list-num pb-4">
                                <li>Check your speakers and volume. </li>
                                <li>Check your microphone and permission for accessing the microphone.</li>
                                <li>If there is still no sounds, please contact us describing the problem in detail.</li>
                            </ul>
                            <p>Mail to: <a href="mailto:support@naturalvoicer.com" className="text-blue">support@naturalvoicer.com</a></p>
                        </div>
                    </details>
                    <details className="mb-5">
                        <summary>
                            <h3 className="text-lg text-gray font-semibold flex items-center justify-between bg-white py-5 px-6 shadow-faq rounded-sm">There is a 2000-characters limit, how can I get more characters? <Arrow className="w-3" fill="currentColor"/></h3>
                        </summary>
                        <div className="bg-blue-darkest bg-opacity-5 py-4 px-6 text-base leading-7">
                            <p>Each user can only convert 2000 characters per day for free. If you need more characters, please upgrade to our premium plans (0.1million characters/$9.99, 0.5million characters/$19.99). Or wait till next day. </p>
                        </div>
                    </details>
                    <p className="text-center pt-10"><a href="/faq" className="underline">More FAQ &gt;&gt;</a></p>
                </div>
            </div>
        </Layout>
    )
}

